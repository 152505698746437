import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const border = '1px solid grey';
const padding = '2px 10px 5px 10px';
const width = '13px';
const height = '13px';
const marginTop = '2px';
const letterSpacing = '0.25';
const fontSize = '12px';
const padding2 = '2px 15px 5px 15px';

export const MN = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
`;

export const BT = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: ${GREYS.doveD};
  border-right: ${border};
  padding: 0px 15px 5px 0px;
  letter-spacing: ${letterSpacing};
  white-space: nowrap;
`;

export const RIGHT = styled.div`
  margin-right: 80px;
`;

export const LEFT = styled.div`
  height: 17px;
  margin-top: ${marginTop};
  display: flex;
  justify-content: space-between;
`;

export const ICONF = styled.div`
  margin-left: 24px;
  border-right: ${border};
  padding ${padding};
  color: ${GREYS.white};
  padding-right: 10px;
  a {
    img {
      width: ${width};
      height: ${height};
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const ICON = styled.div`
  border-right: ${border};
  padding: ${padding};
  a {
    img {
      width: ${width};
      height: ${height};
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const ICONT = styled.div`
  border-right: ${border};
  padding: ${padding2};
  letter-spacing: ${letterSpacing};
  font-size: ${fontSize};
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export const ICONM = styled.div`
  padding: ${padding2};
  letter-spacing: ${letterSpacing};
  font-size: ${fontSize};
`;
