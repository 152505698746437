import React from 'react';
import {
  MN,
  BT,
  RIGHT,
  LEFT,
  ICONF,
  ICON,
  ICONT,
  ICONM,
} from 'UI/apps/RgTopBarApp/RgTopBarApp';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { Translations } from '../core__RgTopBarApp-app';
import PropTypes from 'prop-types';

function TopBarApp({ appConfig }) {
  const link = appConfig;
  const number = appConfig.number;
  const dontShowSocials = appConfig.dontshowsocials;
  const dontShowRG = appConfig.dontshowrg;

  return (
    <>
      <MN>
        <LEFT>
          {!dontShowSocials && (
            <>
              <ICONF>
                <a href={link.facebook}>
                  <img
                    src="//assets.fsbtech.com/react/olybet/social/facebook.svg"
                    alt="facebook"
                  />
                </a>
              </ICONF>
              <ICON>
                <a href={link.instagram}>
                  <img
                    src="//assets.fsbtech.com/react/olybet/social/instagram.svg"
                    alt="instagram"
                  />
                </a>
              </ICON>
              <ICON>
                <a href={link.youtube}>
                  <img
                    src="//assets.fsbtech.com/react/olybet/social/youtube.svg"
                    alt="youtube"
                  />
                </a>
              </ICON>
            </>
          )}
          <ICONT>
            <a href={`tel:${number}`}>{number}</a>
          </ICONT>
          <ICONM>
            <a href={`mailto:${link.mail}`}>{link.mail}</a>
          </ICONM>
        </LEFT>
        <RIGHT>
          {!dontShowRG && (
            <BT onClick={() => PubSub.emit(PubsubEvents.openModals)}>
              {Translations.get('text.responsible.gambling')}
            </BT>
          )}
        </RIGHT>
      </MN>
    </>
  );
}

TopBarApp.propTypes = {
  appConfig: PropTypes.shape({
    dontshowsocials: PropTypes.bool,
    dontshowrg: PropTypes.bool,
    number: PropTypes.string,
  }),
};

export default TopBarApp;
